// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-benefits-centralized-messaging-js": () => import("./../../../src/pages/benefits/centralized-messaging.js" /* webpackChunkName: "component---src-pages-benefits-centralized-messaging-js" */),
  "component---src-pages-benefits-contactless-payment-options-js": () => import("./../../../src/pages/benefits/contactless-payment-options.js" /* webpackChunkName: "component---src-pages-benefits-contactless-payment-options-js" */),
  "component---src-pages-benefits-customer-relationships-js": () => import("./../../../src/pages/benefits/customer-relationships.js" /* webpackChunkName: "component---src-pages-benefits-customer-relationships-js" */),
  "component---src-pages-benefits-effortless-sign-in-js": () => import("./../../../src/pages/benefits/effortless-sign-in.js" /* webpackChunkName: "component---src-pages-benefits-effortless-sign-in-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-benefits-nothing-to-upgrade-or-patch-js": () => import("./../../../src/pages/benefits/nothing-to-upgrade-or-patch.js" /* webpackChunkName: "component---src-pages-benefits-nothing-to-upgrade-or-patch-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-customer-notifications-js": () => import("./../../../src/pages/features/customer-notifications.js" /* webpackChunkName: "component---src-pages-features-customer-notifications-js" */),
  "component---src-pages-features-device-management-js": () => import("./../../../src/pages/features/device-management.js" /* webpackChunkName: "component---src-pages-features-device-management-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-shop-management-js": () => import("./../../../src/pages/features/shop-management.js" /* webpackChunkName: "component---src-pages-features-shop-management-js" */),
  "component---src-pages-features-team-management-js": () => import("./../../../src/pages/features/team-management.js" /* webpackChunkName: "component---src-pages-features-team-management-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

